//Request
import {PostRequest} from "../../../components/request";
//import {store} from "../../storeConfig/store";

export const LoginManual = user => {
    return dispatch => {
        PostRequest('/api/authorize/login',
            {
                'login': user.login,
            }).then((result) => {
            let response = result.data;
            if (response.ok) {
                let result = response.data;
                dispatch({
                    type: "CheckPhone",
                    payload: result,
                });
            } else {
                let result = response.errors;
                dispatch({
                    type: "CheckPhone",
                    payload: result,
                });
            }
        }).catch(err => {
            logout();
        })
    }
}

export const VerifyManual = user => {
    return dispatch => {
        PostRequest('/api/authorize/verify',
            {
                'name': user.firstName,
                'lastname': user.lastName,
                'code': user.code,
                'token': user.verify_token
            }).then((result) => {
            let response = result.data;
            if (response.ok) {
                let result = response.data;
                localStorage.setItem("token", result);
                dispatch({
                    type: "LOGIN",
                    payload: result,
                });
            } else {
                let result = response.errors;
                dispatch({
                    type: "ERROR",
                    payload: result,
                });
            }
        }).catch(err => {
            logout();
        })
    }
}

export const logout = () => {
    return dispatch => {
        localStorage.removeItem("token");
        dispatch({type: "LOGOUT", payload: {}});
    }
    //window.location.reload();
}
